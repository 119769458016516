var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-med"},[_c('Breadcrumb',{attrs:{"items":_vm.category.navigationTypeId != 0 ? _vm.breadcrumbs : _vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1 position-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('CategoryTitle',{staticClass:"product-category-title",attrs:{"category":_vm.category,"selectedCategory":_vm.selectedCategory}}),(_vm.category.children)?_c('div',[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t("categoryMed.goToSubcategoriesText")))]),_c('div',{staticClass:"subcategories mt-3 overflow-y-hidden"},_vm._l((_vm.category.children),function(subCategory){return _c('v-chip',{key:subCategory.categoryId,staticClass:"mr-2 mb-2 rounded-lg",attrs:{"label":"","link":"","to":_vm.category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: _vm.category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }}},[_vm._v(" "+_vm._s(subCategory.name)+" ")])}),1)]):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('ProductListGrid',{key:_vm.categoryId || _vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hideFilterString":"Categorie,Main Term"},on:{"productsCount":_vm.updateCount}})],1),_c('category-block',{staticClass:"category-block position-2",attrs:{"target":_vm.category,"position":"position2"}}),_c('category-block',{staticClass:"category-block position-3",attrs:{"target":_vm.category,"position":"position3"}}),_c('v-container',{attrs:{"fluid":""}},[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_DESCRIPTION","tag":"div"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }